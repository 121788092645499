import React, { useContext, useMemo, useState } from "react";

import ContainerAuthenticated from "containers/Authenticated";
import useWindowSize from "utils/useWindowSize";


import { InputContente, CartProductsContent, Title, CartProductQuantity, ContentTitle, CartProductSummary } from './styled';
import Input from "components/Form/Input";
import Button from "components/Form/Button";
import { Icon } from "ui/styled";
import CartProductList from "components/CartProductList";
import PurchaseSummary from "components/PurchaseSummary";
import CheckoutContent from "components/CheckoutContent";
import { useHistory } from 'react-router-dom';
import ShippingDeadline from "components/SellerDetails/ShippingDeadline";
import { CoreContext } from "context/CoreContext";
import useAnalytics from "hooks/useAnalytics";





export default function Cart() {
    const history = useHistory();
    const navigate = to => history.push(`/${to}`);

    const { cart, setCart } = useContext(CoreContext)
    const { track } = useAnalytics();

    const total_quantity = useMemo(() => {
        return cart?.reduce((p, c) => p + (parseFloat(c?.quantity) || 1), 0) || 0
    }, [cart])

    const total_price = useMemo(() => {
        return parseFloat(cart?.reduce((p, c) => p + ((parseFloat(c?.quantity) || 1) * parseFloat(c?.price)), 0) || 0).toFixed(2)
    }, [cart])

    const buy = () => {
        track('menimports', { item_id: cart?.map(m => m?.ref_id).join(','), item_name: cart?.map(m => m?.title).join(','), item_category: cart?.map(m => m?.product_type).join(','), value: total_price, utm_source: cart?.map(m => m?.source).join(',') })

        const qs = cart?.map(m => `sc=1&sku=${m?.ref_id}&qty=${m?.quantity}&seller=1`)?.join('&')
        window.location.href = (`https://www.menimport.com.br/checkout/cart/add?${qs}`)
        setCart([])
    }

    const onRemove = (item) => {
        setCart(cart?.filter(m => m?.id !== item?.id))
        track('remove_from_cart', { item_id: item?.ref_id, item_name: item?.title, item_category: item?.product_type, value: item?.price, utm_source: item?.source })
    }

    return (
        <>
            <ContainerAuthenticated>
                <CheckoutContent title="Meu carrinho" tabType='Sacola'>
                    <InputContente>
                        {/* <ShippingDeadline full /> */}
                    </InputContente>
                    <CartProductSummary>
                        <CartProductsContent>
                            <ContentTitle>
                                <Title>Produtos no carrinho</Title>
                                <CartProductQuantity>{`${total_quantity} Produtos`}</CartProductQuantity>
                            </ContentTitle>
                            {
                                cart.map(item => (
                                    <CartProductList items={item} onChange={quantity => setCart(cart?.map(m => m?.id === item?.id ? { ...m, quantity } : m))} onRemove={() => onRemove(item)} />
                                ))
                            }
                        </CartProductsContent>
                        <PurchaseSummary productsTotal={total_price} servicesTotal={0} grandTotal={total_price} qtd={total_quantity} onCheckout={buy} onContinueShopping={() => navigate("produtos")} />
                    </CartProductSummary>
                </CheckoutContent>
            </ContainerAuthenticated>
        </>
    );
}
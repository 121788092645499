const hubspotFormId = "7dbcd67d-838e-4c7e-8d2a-0fac4871c024";
const hubspotPortalId = "46178129";

export const CreateForm = async (data) => {
  try {
    const response = await fetch(`https://api.hsforms.com/submissions/v3/integration/submit/${hubspotPortalId}/${hubspotFormId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      const error = await response.json();
      console.error("HubSpot Error:", error);
      throw new Error(`Failed to submit data: ${response.status}`);
    }

    return response.json(); // Retorna a resposta para debug.
  } catch (error) {
    console.error("CreateForm Error:", error.message);
    throw error; // Propaga o erro para quem chamou a função.
  }
};

export const getUserIp = async () => {
  const response = await fetch('https://api.ipify.org?format=json');
  const data = await response.json();
  return data.ip;
};
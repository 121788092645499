import React, { useState } from 'react';
import { CartProductContent, CartProductListContainer, FreightContent, FreightMethod, FreightText, FreightTitle, ProductContainer, ProductContent, ProductDelete, ProductImg, ProductImgContainer, ProductPrice, ProductPriceContainer, ProductQuantity, ProductText, ProductTitle, SellerContent, Table, TableHeadCell } from './styled';
import Select from 'components/Form/Select';
import { options } from 'utils/options';
import Button from 'components/Form/Button';
import { Icon } from 'ui/styled';
import { parseCurrency } from 'utils/parsers';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';



export default function CartProductList({ items, noFreight, onChange, onRemove }) {
  const history = useHistory();
  const navigate = to => history.push(`/${to}`);

  const [selectedOption, setSelectedOption] = useState(items?.quantity || 1);

  const handleNavigate = () => {
    const formattedName = items?.title?.replace(/\s+/g, '-');
    navigate(`produtos/${formattedName}`);
  }

  const handleChange = (qt) => {
    setSelectedOption(qt);
  };

  return (
    <>
      <CartProductListContainer>
        <Table>
          <CartProductContent>
            <ProductContent >
              <TableHeadCell product>Produto</TableHeadCell>
              <ProductContainer>

                <ProductImgContainer onClick={handleNavigate}>
                  <ProductImg src={items.image_link} />
                </ProductImgContainer>

                <ProductTitle onClick={handleNavigate}>
                  {items.title}
                  {/* <SellerContent>
                    <ProductText>Vendido e entregue por:
                    </ProductText>
                    <ProductText left bold>{items.product?.seller}</ProductText>
                  </SellerContent> */}
                </ProductTitle>
              </ProductContainer>
            </ProductContent>
            <ProductContent>
              <TableHeadCell qtd> Qtd.</TableHeadCell>
              <ProductQuantity>
                <Select
                  options={options}
                  value={items?.quantity}
                  onChange={onChange}
                  width='58px'
                  noRadius
                  padding='16px 0px 16px 13px'
                />
              </ProductQuantity>
            </ProductContent>
            <ProductContent>

              < TableHeadCell qtd> Preço</TableHeadCell>
              <ProductPriceContainer>
                {items.price && <ProductPrice descont>{parseCurrency(items?.price)}</ProductPrice>}
                <ProductPrice>{parseCurrency(items?.price)}</ProductPrice>
              </ProductPriceContainer>
            </ProductContent>
            <ProductDelete>
              <Button
                onClick={onRemove}
                nospace
                link
              >
                <Icon src='/icons/delete.svg' />
              </Button>
            </ProductDelete>
          </CartProductContent>
        </Table>
        {/* {noFreight ? null : <FreightContent>
          <FreightTitle>Frete: </FreightTitle>
          <FreightText>
            {items.shipping && `${items.shipping?.method}`}
            <FreightMethod>{`(${items.shipping?.duration})`} </FreightMethod>
            <FreightMethod>R$ {items.shipping?.cost} </FreightMethod>
          </FreightText>
        </FreightContent>} */}
      </CartProductListContainer >
    </>

  );
};

